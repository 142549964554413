import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from "../ui/composite/pages/errorpages/404";
import {
  APPOINTMENT,
  DOCTORS,
  ERROR,
  HOME,
  SERVICES,
  INSURANCE,
  JENYO,
  INFORMATION
} from "./auth/routes";
import { DotLoader } from "react-spinners";

// noinspection JSCheckFunctionSignatures
const HomePage = lazy(() => import("../ui/composite/pages/home"));

// services page
const ServicesPage = lazy(() => import("../ui/composite/pages/services"));

// book appointment page
const AppointmentPage = lazy(() => import("../ui/composite/pages/appointment"));

// our doctors page
const DoctorsPage = lazy(() => import("../ui/composite/pages/doctors"));

// doctor profile page
const DoctorJenyoProfile = lazy(() =>
  import("../ui/composite/pages/doctorJenyoProfile")
);
const Insurance = lazy(() =>
  import("../ui/composite/pages/insurance")
);
const Information = lazy(() =>
  import("../ui/composite/pages/information")
);

function AppRoutes() {
  return (
    <>
      <Suspense
        fallback={
          <div className={"loader"}>
            <DotLoader />
          </div>
        }
      >
        <BrowserRouter>
          <Switch>
            <Route exact path={HOME} component={HomePage} />
            <Route exact path={SERVICES} component={ServicesPage} />
            <Route exact path={APPOINTMENT} component={AppointmentPage} />
            <Route exact path={DOCTORS} component={DoctorsPage} />
            <Route exact path={JENYO} component={DoctorJenyoProfile} />
            <Route exact path={INSURANCE} component={Insurance} />
            <Route exact path={INFORMATION} component={Information} />
            <Route path={ERROR} component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default AppRoutes;
