import {siteColors} from './sitecolors'
import {fontSizes} from "./siteFontSizes";
import {fontWeight} from "./siteFontWeight";

const variables = {
    siteColors,
    fontSizes,
    fontWeight,
    
};

export default variables;
